@import url('https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css');
@import url('https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

.cursive {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
